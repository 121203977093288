// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-es-js": () => import("./../../../src/pages/contact.es.js" /* webpackChunkName: "component---src-pages-contact-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-en-js": () => import("./../../../src/pages/info.en.js" /* webpackChunkName: "component---src-pages-info-en-js" */),
  "component---src-pages-info-es-js": () => import("./../../../src/pages/info.es.js" /* webpackChunkName: "component---src-pages-info-es-js" */),
  "component---src-pages-photos-en-js": () => import("./../../../src/pages/photos.en.js" /* webpackChunkName: "component---src-pages-photos-en-js" */),
  "component---src-pages-photos-es-js": () => import("./../../../src/pages/photos.es.js" /* webpackChunkName: "component---src-pages-photos-es-js" */)
}

